import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'

import { firebaseApp } from './plugins/firebase'
import posthogPlugin from "./plugins/posthog";
import trackdeskPlugin from './plugins/trackdesk';

import { ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { VueFire, VueFireAuth, VueFireAppCheck } from 'vuefire'
import * as Sentry from "@sentry/vue";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import {all} from '@awesome.me/kit-9e7c45db70/icons'

import vue3ToPdf from 'vue3-to-pdf'

import './style.css'
import { router } from './router'
import App from './App.vue'

library.add(...all)

const pinia = createPinia()
const head = createHead()

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('font-awesome-layers', FontAwesomeLayers)
app.component('font-awesome-layer-text', FontAwesomeLayersText)


/*Sentry.init({
  app,
  dsn: "https://9937818a6994dfb55ebe1b5fd707d6c1@o4506042722549760.ingest.us.sentry.io/4507129930842113",
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      isNameRequired: true,
      isEmailRequired: true,
      buttonLabel: "Bug melden",
      submitButtonLabel: "Feedback absenden",
      formTitle: "Feedback geben",
      nameLabel: "Name",
      namePlaceholder: "Dein Name",
      emailLabel: "E-Mail",
      emailPlaceholder: "Deine E-Mail",
      messageLabel: "Beschreibung",
      messagePlaceholder: "Dein Feedback",
      isRequiredLabel: "(Pflichtfeld)",
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/firestore\.googleapis\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  trackComponents: true,

});*/

app.use(posthogPlugin);
app.use(trackdeskPlugin);

app.use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuth()
    ],
  })
app.use(pinia)
app.use(router)
app.use(head)
app.use(vue3ToPdf)


app.mount('#app')


console.log('Running in', import.meta.env.MODE)
